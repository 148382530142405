.admin-chart {
  width: 100%;
  height: 500px;
  font-size: 11px;
}

.amcharts-export-menu-top-right {
  top: 10px;
  right: 0;
}

#funnelChart, #lineChart {
}

#pieChart {
  max-width: 1120px;
}

.amcharts-pie-slice {
  transform: scale(1);
  transform-origin: 50% 50%;
  transition-duration: 0.3s;
  transition: all .3s ease-out;
  cursor: pointer;
  box-shadow: 0 0 30px 0 #000;
}

.amcharts-pie-slice:hover {
  transform: scale(1.1);
  filter: url(#shadow);
}

.amChartsButtonSelected {
  background-color: #CC0000;
  border: 1px solid #CC0000;
  color: $default-text;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 1px;
}

.amChartsButton {
  background-color: #EEEEEE;
  border: 1px solid #CCCCCC;
  color: #000000;
  border-radius: 5px;
  margin: 1px;
}

.ct-area {
  fill-opacity: .5;
}

.ct-label{
  color: $default-text;
  opacity: 0.9;
  fill: $default-text;
}

.ct-chart .ct-label{
  font-size: 1em;
}

.ct-chart svg{
  width: 100%;
  display: block;
}

.ct-series-a {
  .ct-bar,  .ct-line, .ct-point, .ct-slice-donut, .ct-slice-pie {
    stroke: $primary;
  }
  .ct-slice-pie, .ct-area{
    fill: $primary;
  }
}

.ct-series-b {
  .ct-bar,  .ct-line, .ct-point, .ct-slice-donut, .ct-slice-pie {
    stroke: $success;
  }
  .ct-slice-pie, .ct-area{
    fill: $success;
  }
}

.ct-series-c {
  .ct-bar,  .ct-line, .ct-point, .ct-slice-donut, .ct-slice-pie {
    stroke: $danger;
  }
  .ct-slice-pie, .ct-area{
    fill: $danger;
  }
}

.ct-series-d {
  .ct-bar,  .ct-line, .ct-point, .ct-slice-donut, .ct-slice-pie {
    stroke: $warning;
  }
  .ct-slice-pie, .ct-area{
    fill: $warning;
  }

}

.ct-series-e {
  .ct-bar,  .ct-line, .ct-point, .ct-slice-donut, .ct-slice-pie {
    stroke: $info;
  }
  .ct-slice-pie, .ct-area{
    fill: $info;
  }
}

@media screen and (min-width: 992px) {
  .row.morris-up {
    > div {
      margin-top: -434px;
    }
  }
}

.area-morris-header{
  margin-top: 20px;
}

.stacked-bar .ct-bar{
  stroke-width: 30px;
}

.amChartsCompareList {
  border: 1px solid #CCCCCC;
}

.pie-chart-panel {
  padding: 0;
}

.chart-panel{
  height: 495px;
}

#filterChart {
  width		: 96%;
  font-size	: 11px;
  margin-left: auto;
  margin-right: auto;
  margin-top:15px;
}

.amcharts-graph-g1 .amcharts-graph-fill {
  filter: url(#blur);
}

.amcharts-graph-g2 .amcharts-graph-fill {
  filter: url(#blur);
}

.amcharts-cursor-fill {
  filter: url(#shadow);
}

.chartist h5 {
  font-weight: $font-normal;
}



$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) !default;
$ct-series-colors: (
    $primary,
    $success,
    $danger,
    $warning,
    $info,
    #d70206,
    #f05b4f,
    #f4c63d,
    #d17905,
    #453d3f,
    #59922b,
    #0544d3,
    #6b0392,
    #f05b4f,
    #dda458
) !default;

.ct-legend {
    position: relative;
    z-index: 10;

    li {
        position: relative;
        padding-left: 23px;
        margin-bottom: 3px;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}